@import '../../scss/config';

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - #{$nav-height});
  padding: 36px 12px;

  &.modal {
    min-height: 100vh;
  }

  @media (max-width: $break-mobile-large) {
    padding-bottom:0;
  }
}
